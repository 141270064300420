import React from 'react';
import styled from 'styled-components';

const TableWrapper = styled.table`
  background: #fff;
  width: 100%;
  text-align: center;
  border: 1px solid #792037;
  border-collapse: collapse;

  & thead {
    background-color: #792037;
    display: table-header-group;
    vertical-align: middle;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }

  & th {
    height: 95px;
    vertical-align: middle;
  }

  & td {
    width: 50%;
    height: 70px;
    border: 1px solid #792037;
    vertical-align: middle;
  }
`;

class PriceTab extends React.PureComponent {
  render() {
    return (
      <TableWrapper>
        <thead>
          <tr>
            <th colSpan="2">{this.props.title}<br />{this.props.date}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{this.props.firstColumn.title}</td>
            <td>{this.props.secondColumn.title}</td>
          </tr>
          <tr>
            <td>{this.props.firstColumn.value}€</td>
            <td>{this.props.secondColumn.value}€</td>
          </tr>
        </tbody>
      </TableWrapper>
    );
  }
}

export default PriceTab;
