import React from "react";
import { Flex, Box } from "grid-styled";

import Title from "../../components/Title";

const Photo = ({ photo, description }) => (
  <Box width={1 / 3} mx={1} my={1}>
    <img
      src={photo}
      alt={description}
      style={{ maxWidth: 280, width: "100%" }}
      loading="lazy"
    />
  </Box>
);

class PhotosSection extends React.PureComponent {
  render() {
    return (
      <Flex
        width={[1, 1, 900]}
        px={[1, 1, 0]}
        mx="auto"
        my={3}
        column
        align="center"
        is="section"
      >
        <Title>{this.props.title}</Title>
        <Flex width={1} align="center" justify="center">
          {this.props.photos.length &&
            this.props.photos.map((photo, index) => (
              <Photo {...photo} key={index} />
            ))}
        </Flex>
      </Flex>
    );
  }
}

export default PhotosSection;
