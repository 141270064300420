import React, { Component } from "react";
import styled from "styled-components";

const CardWrapper = styled.div`
  background: #fff;
  max-width: 290px;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
  color: #343434;
  padding: 20px 20px;
`;

const TitleWrapper = styled.span`
  display: block;
  font-weight: 600;
  margin-top: 30px;
`;

const DescriptionWrapper = styled.span`
  display: block;
  margin-top: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 54px;
  max-height: 54px;
`;

class Card extends Component {
  render() {
    return (
      <CardWrapper>
        <IconWrapper>
          <img src={this.props.icon} alt="" loading="lazy" />
        </IconWrapper>
        <TitleWrapper>{this.props.title}</TitleWrapper>
        <DescriptionWrapper>{this.props.description}</DescriptionWrapper>
      </CardWrapper>
    );
  }
}

export default Card;
