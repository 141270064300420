import React from "react";
import { Box, Flex } from "grid-styled";
import { responsiveStyle } from "styled-system";
import { FormattedMessage } from "react-intl";

const Wrapper = Flex.extend`
  background: #ececec;

  h1 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    color: ${(props) => (props.light ? "#fff" : "#343434")};
    padding: 0 0 15px;
    text-transform: uppercase;
  }
`;

const height = responsiveStyle({
  prop: "height",
  cssProperty: "height",
});

const VideoWrapper = Box.extend`
  ${height};
`;

class YoutubeVideoSection extends React.PureComponent {
  render() {
    return (
      <Wrapper width={1} py={4} mt={3} column align="center" is="section">
        <h1>
          <FormattedMessage id="home.seo" />
        </h1>
        <VideoWrapper
          width={[320, 640, 960]}
          height={["240px", "480px", "540px"]}
        >
          <iframe
            id="player"
            title="Youtube Video"
            type="text/html"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/InN3ODF1l5o?showinfo=0&playsinline=1&modestbranding=0&fs=1&rel=0"
            frameBorder="0"
            allowFullScreen="allowfullscreen"
          />
        </VideoWrapper>
      </Wrapper>
    );
  }
}

export default YoutubeVideoSection;
