import React, { Component } from 'react';
import { Flex } from 'grid-styled';
import PiqueroqueMap from '../../components/PiqueroqueMap';

const GOOGLE_KEY = 'AIzaSyDUn-3dfq8e_uUAw77hJZtb9kEF_PCRgNI';

const MapWrapper = Flex.extend`
  height: 400px;
`;

class MapSection extends Component {
  render() {
    return (
      <MapWrapper width={1} column align="center" is="section">
        <PiqueroqueMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ width: '100%', height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </MapWrapper>
    );
  }
}

export default MapSection;
