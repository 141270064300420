import React, { Component } from "react";
import PropTypes from "prop-types";
import Route from "react-router-dom/Route";
import Redirect from "react-router-dom/Redirect";
import Switch from "react-router-dom/Switch";
import withRouter from "react-router-dom/withRouter";
import Helmet from "react-helmet";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { createClient } from "contentful/dist/contentful.browser.min.js";

import messages from "./messages";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Home from "../Home";
import Place from "../Place";
import Contact from "../Contact";
import NotFound from "../NotFound";
import ScrollToTop from "./ScrollToTop";

const AppWrapper = styled.div`
  min-height: 90vh;
  margin-top: 50px;
`;

const client = createClient({
  space: "34lkscd8swqe",
  accessToken:
    "c10a7d249a82857b01a3b4e99a5059052176f504dbc7ace0caea15d23e085c1a",
});

function parseEntry(entry, assets) {
  return {
    ...entry.fields,
    photos: entry.fields.photos.map((photo) => {
      const s = assets.find((a) => a.sys.id === photo.sys.id);
      return {
        src: s.fields.file.url,
        width: s.fields.file.details.image.width,
        height: s.fields.file.details.image.height,
      };
    }),
  };
}

export class App extends Component {
  state = { guestRoom: null, cottage: null };

  async componentDidMount() {
    const { intl } = this.props;

    // Get all assets (limit 100)
    let { items } = await client.getAssets();

    // Fetch guest room data in Contentful
    const guestRoomEntryId = "2xLdNnUvr4UluHXgxEcMUl";
    const guestRoom = await client.getEntry(guestRoomEntryId);
    const guestRoomData = parseEntry(guestRoom, items);

    const GUEST_ROOM = {
      title: intl.formatMessage(messages.guestroom),
      description: intl.formatMessage(messages.guestroomDescription),
      photos: guestRoomData.photos || [],
      lowSeason: {
        title: intl.formatMessage(messages.lowSeason),
        date: intl.formatMessage(messages.lowSeasonDate),
        firstColumn: {
          title: intl.formatMessage(messages.guestroomFirstColumnLabel),
          value: guestRoomData.lowSeason_firstColumn || 0,
        },
        secondColumn: {
          title: intl.formatMessage(messages.guestroomSecondColumnLabel),
          value: guestRoomData.lowSeason_secondColumn || 0,
        },
      },
      highSeason: {
        title: intl.formatMessage(messages.highSeason),
        date: intl.formatMessage(messages.highSeasonDate),
        firstColumn: {
          title: intl.formatMessage(messages.guestroomFirstColumnLabel),
          value: guestRoomData.highSeason_firstColumn || 0,
        },
        secondColumn: {
          title: intl.formatMessage(messages.guestroomSecondColumnLabel),
          value: guestRoomData.highSeason_secondColumn || 0,
        },
      },
      information: intl.formatMessage(messages.guestroomPricesInformation),
    };

    // Fetch cottage data in Contentful
    const cottageEntryId = "7rcTbtde0YbhQbhJXtdGuL";
    const cottage = await client.getEntry(cottageEntryId);
    const cottageData = parseEntry(cottage, items);

    const COTTAGE = {
      title: intl.formatMessage(messages.cottage),
      description: intl.formatMessage(messages.cottageDescription),
      photos: cottageData.photos || [],
      lowSeason: {
        title: intl.formatMessage(messages.lowSeason),
        date: intl.formatMessage(messages.lowSeasonDate),
        firstColumn: {
          title: intl.formatMessage(messages.cottageFirstColumnLabel),
          value: cottageData.lowSeason_firstColumn || 0,
        },
        secondColumn: {
          title: intl.formatMessage(messages.cottageSecondColumnLabel),
          value: cottageData.lowSeason_secondColumn || 0,
        },
      },
      highSeason: {
        title: intl.formatMessage(messages.highSeason),
        date: intl.formatMessage(messages.highSeasonDate),
        firstColumn: {
          title: intl.formatMessage(messages.cottageFirstColumnLabel),
          value: cottageData.highSeason_firstColumn || 0,
        },
        secondColumn: {
          title: intl.formatMessage(messages.cottageSecondColumnLabel),
          value: cottageData.highSeason_secondColumn || 0,
        },
      },
      taxes: intl.formatMessage(messages.cottageTaxes),
      information: intl.formatMessage(messages.cottagePricesInformation),
    };

    this.setState({ guestRoom: GUEST_ROOM, cottage: COTTAGE });
  }

  render() {
    const { intl } = this.props;

    return (
      <ScrollToTop>
        <Helmet
          titleTemplate="%s | Piqueroque | St-Emilion"
          defaultTitle="Piqueroque - gîte et chambres d'hôtes près de St-Emilion"
        >
          <html lang={this.props.locale} />
          <meta
            name="description"
            content="À 10 minutes de Saint-Emilion, à mi-chemin entre Bordeaux et Bergerac et à deux pas du Grand Saint Emilionnais Golf Club, Agnès et Didier vous accueillent au coeur des vignobles des Côtes de Castillon."
          />
        </Helmet>
        <Header lang={this.props.locale} />
        <AppWrapper>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path={`/${this.props.locale}`} component={Home} />
            <Redirect
              exact
              from="/gite"
              to={intl.formatMessage({ id: "route.cottage" })}
            />
            <Route
              path={intl.formatMessage({ id: "route.cottage" })}
              render={() => <Place type="cottage" place={this.state.cottage} />}
            />
            <Redirect
              exact
              from="/chambre-hotes"
              to={intl.formatMessage({ id: "route.guestroom" })}
            />
            <Route
              path={intl.formatMessage({ id: "route.guestroom" })}
              render={() => (
                <Place type="guestroom" place={this.state.guestRoom} />
              )}
            />
            <Redirect
              exact
              from="/contact"
              to={intl.formatMessage({ id: "route.contact" })}
            />
            <Route
              path={intl.formatMessage({ id: "route.contact" })}
              component={Contact}
            />
            <Route path="/404" component={NotFound} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </AppWrapper>
      </ScrollToTop>
    );
  }
}

App.propTypes = {
  locale: PropTypes.oneOf(["fr", "en"]).isRequired,
};

App.defaultProps = {
  locale: "fr",
};

function mapStateToProps({ language }) {
  return {
    locale: language.locale,
  };
}

export default withRouter(connect(mapStateToProps)(injectIntl(App)));
