import React from 'react';
import { Flex } from 'grid-styled';
import styled from 'styled-components';
import Transition from 'react-transition-group/Transition';

import NavToggler from './Nav/NavToggler';
import NormalNav from './Nav/NormalNav';
import MobileNav from './Nav/MobileNav';
import LanguageBar from './LanguageBar';

const HeaderWrapper = Flex.extend`
  background-color: #343434;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 3;
`;

const Shadow = styled.div`
  display: ${props => (['entered'].includes(props.state) ? 'inline' : 'none')};
  background: rgba(
    0,
    0,
    0,
    ${props => (['entered'].includes(props.state) ? 0.8 : 0)}
  );
  position: fixed;
  top: 50px;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: background ${props => props.duration}ms ease-in-out;
  cursor: pointer;
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileNavOpened: false,
    };
  }

  toggleMobileNav = (event) => {
    event && event.preventDefault();
    this.setState({ isMobileNavOpened: !this.state.isMobileNavOpened });
  }

  render() {
    const { isMobileNavOpened } = this.state;

    return (
      <HeaderWrapper align="center" justify="space-between" is="header" pl={[20, 20, 0]}>
        <NavToggler isMobileNavOpened={isMobileNavOpened} onClick={this.toggleMobileNav} />
        <NormalNav />
        <LanguageBar />
        <Transition
          timeout={150}
          in={isMobileNavOpened}
          appear
        >
          {state => <Shadow state={state} duration={150} />}
        </Transition>
        {isMobileNavOpened && <MobileNav toggleMobileNav={this.toggleMobileNav} />}
      </HeaderWrapper>
    );
  }
}

export default Header;
