import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import withRouter from "react-router-dom/withRouter";

import messages from "../../messages";
import { changeLocale } from "./actions";

const ROUTES_REDIRECT = {
  "/": "route.home",
  "/gite": "route.cottage",
  "/chambre-hotes": "route.guestroom",
  "/contact": "route.contact",
};

export class LanguageProvider extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  componentDidMount() {
    if (/\/fr/.test(this.props.location.pathname)) {
      console.log("FR site detected");
      this.props.changeLocale("fr");
    } else if (/\/en/.test(this.props.location.pathname)) {
      console.log("EN site detected");
      this.props.changeLocale("en");
    } else {
      const locale = this.detectLocale();
      console.log("Apply locale", locale);
      this.props.changeLocale(locale);
      const redirect =
        messages[locale][ROUTES_REDIRECT[this.props.location.pathname]];
      console.log("Redirect to", redirect);
      this.props.history.replace(redirect);
    }
  }

  detectLocale() {
    const language =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

    console.log("Detected language", languageWithoutRegionCode);

    if (!["fr", "en"].includes(languageWithoutRegionCode)) {
      // default language
      return "fr";
    } else {
      return languageWithoutRegionCode;
    }
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.locale}
        // Needs to allow non-blocking nav
        key={`${this.props.locale}${this.props.location.pathname}`}
        messages={this.props.messages[this.props.locale]}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

LanguageProvider.defaultProps = {
  locale: "fr",
};

function mapStateToProps({ language }) {
  return {
    locale: language.locale,
  };
}

export default withRouter(
  connect(mapStateToProps, { changeLocale })(LanguageProvider)
);
