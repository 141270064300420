import React from "react";
import { Flex } from "grid-styled";
import ReactMarkdown from "react-markdown";
import { injectIntl } from "react-intl";

import Title from "../../components/Title";
import Button from "../../components/Button";

class WelcomeSection extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <Flex
        width={[1, 1, 900]}
        px={2}
        mx="auto"
        my={3}
        column
        align="center"
        is="section"
        style={{ textAlign: "center" }}
      >
        <Title>{this.props.title}</Title>
        <ReactMarkdown source={this.props.text} />
        <Flex
          width={1}
          mx="auto"
          my={3}
          direction={["column", "row", "row"]}
          align="center"
        >
          <Button to={intl.formatMessage({ id: "route.cottage" })}>
            {this.props.cottageButton}
          </Button>
          <Button to={intl.formatMessage({ id: "route.guestroom" })}>
            {this.props.guestRoomButton}
          </Button>
        </Flex>
      </Flex>
    );
  }
}

export default injectIntl(WelcomeSection);
