import styled from "styled-components";
import { darken } from "polished";

const Link = styled.a`
  color: #792037;
  text-decoration: underline;

  :hover {
    color: ${darken(0.08, "#792037")};
  }
`;

export default Link;
