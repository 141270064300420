import { injectGlobal } from 'styled-components';

/* eslint no-unused-expressions: 0 */
injectGlobal`
  html,
  body {
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    font-family: Helvetica, Arial, sans-serif;
    position: relative;
  }

  .fontLoaded {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }

  #root {
    min-height: 100vh;
  }
`;
