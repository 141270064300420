import React from "react";
import Helmet from "react-helmet";
import { Flex } from "grid-styled";
import { injectIntl, FormattedMessage } from "react-intl";

import Link from "../../components/Link";
import Text from "../../components/Text";
import Title from "../../components/Title";
// import FormSection from "./FormSection";
import MapSection from "./MapSection";

class Contact extends React.Component {
  render() {
    const { intl } = this.props;

    return (
      <div style={{ background: "#ececec" }}>
        <Helmet>
          <title>Contact</title>
          <link
            rel="alternate"
            href="https://piqueroque.fr/fr/contact"
            hreflang="fr"
          />
          <link
            rel="alternate"
            href="https://piqueroque.fr/en/contact"
            hreflang="en"
          />
          <link
            rel="alternate"
            href="https://piqueroque.fr/contact"
            hreflang="x-default"
          />
        </Helmet>
        <Flex
          width={[1, 1, 900]}
          px={2}
          mx="auto"
          my={3}
          column
          align="center"
          is="section"
        >
          <Title>
            <FormattedMessage id="contact.title" />
          </Title>
          <Text align="center">
            Agnès et Didier CERBELLE
            <br />1 Moulin de Piqueroque - 33350 Saint-Philippe d’Aiguilhe
          </Text>
          <Text align="center">
            <FormattedMessage id="contact.message" />
            <br />
            <Link
              href={`mailto:${intl.formatMessage({
                id: "contact.mail",
              })}?subject=${intl.formatMessage({ id: "contact.subject" })}`}
            >
              <FormattedMessage id="contact.mail" />
            </Link>{" "}
            <FormattedMessage id="contact.or" />{" "}
            <Link href={`tel:+33628204654`}>
              <FormattedMessage id="contact.phone" />
            </Link>
          </Text>
        </Flex>
        {/* <FormSection /> */}
        <MapSection />
      </div>
    );
  }
}

export default injectIntl(Contact);
