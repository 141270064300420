import styled from 'styled-components'

const Svg = styled.svg`
  svg {
    display: inline-block;
    path {
      fill: currentColor;
    }
  }
`

export default Svg;
