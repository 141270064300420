import styled from "styled-components";

const Title = styled.h3`
  position: relative;
  font-family: Raleway;
  font-size: 25px;
  font-weight: 500;
  color: ${(props) => (props.light ? "#fff" : "#343434")};
  padding: 0 0 15px;
  text-transform: uppercase;

  @media screen and (min-width: 40em) {
    font-size: 30px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 93px;
    height: 2px;
    background: ${(props) => (props.light ? "#fff" : "#343434")};
  }
`;

export const TitleSeo = styled.h1`
  position: relative;
  font-family: Raleway;
  font-size: 25px;
  font-weight: 500;
  color: ${(props) => (props.light ? "#fff" : "#343434")};
  padding: 0 0 15px;
  text-transform: uppercase;

  @media screen and (min-width: 40em) {
    font-size: 30px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 93px;
    height: 2px;
    background: ${(props) => (props.light ? "#fff" : "#343434")};
  }
`;

export default Title;
