import React, { Component } from 'react';
import { Flex } from 'grid-styled';
import Lightbox from 'react-images';
import Gallery from 'react-photo-gallery';

const THEME = {
  container: {
    color: '#ffffff',
  },
  arrow: {
    opacity: 0.6,
    transition: 'opacity 200ms',
    ':hover': {
      opacity: 1,
    },
  },
  arrow__size__medium: {
    height: 40,
    marginTop: -20,

    '@media (min-width: 768px)': {
      height: 70,
      padding: 15,
    },
  },
  arrow__direction__left: { marginLeft: 10 },
  arrow__direction__right: { marginRight: 10 },
  close: {
    opacity: 0.6,
    transition: 'opacity 200ms',
    ':hover': {
      opacity: 1,
    },
  },
};

class Photos extends Component {
  constructor(props) {
    super(props);
    this.state = { currentImage: 0, columns: 0 };
  }

  openLightbox = (event, obj) => {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
  }

  gotoNext = () => {
    this.setState({ currentImage: this.state.currentImage + 1 });
  }

  handleResize = () => {
    let columns = 1;
    if (window.innerWidth <= 700){
      columns = 3;
    } else if (window.innerWidth <= 960) {
      columns = 4;
    } else if ((this.props.photos.length % 2) === 0) {
      columns = 5;
    } else {
      columns = 6;
    }

    this.setState({ columns })
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    return (
      <Flex
        width={1}
        mx="auto"
        my={3}
        column
        align="center"
        is="section"
        style={{ flexBasis: '80%' }}
      >
        <div style={{ width: '100%' }}>
          <Gallery columns={this.state.columns} photos={this.props.photos} onClick={this.openLightbox} />
        </div>
        <Lightbox images={this.props.photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
          theme={THEME}
          backdropClosesModal
          imageCountSeparator=" / "
        />
      </Flex>
    );
  }
}

export default Photos;
