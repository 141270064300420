/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';

import languageReducer from './containers/LanguageProvider/reducer';
import { routerReducer } from 'react-router-redux';

export default function createReducer(injectedReducers) {
  return combineReducers({
    router: routerReducer,
    language: languageReducer,
    ...injectedReducers,
  });
}
