import React from 'react';

import Svg from './../../Svg';

export const CloseIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24">
    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="square" strokeWidth="2">
      <path d="M2.192 2.9L21.284 21.99M21.284 2.192L2.192 21.284"/>
    </g>
  </Svg>
);

export const HamburgerIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 23">
    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="square" strokeWidth="2">
      <path d="M1.5 1h27M1.5 11h27M1.5 22h27"/>
    </g>
  </Svg>
);
