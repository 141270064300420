import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

import silverTheme from "./silverTheme.json";

const PiqueroqueMap = () => (
  <GoogleMap
    defaultZoom={13}
    defaultCenter={{ lat: 44.9119948, lng: -0.0222489 }}
    defaultOptions={{ styles: silverTheme }}
  >
    <Marker position={{ lat: 44.9119948, lng: -0.0222489 }}>
      <InfoWindow>
        <div>Piqueroque</div>
      </InfoWindow>
    </Marker>
  </GoogleMap>
);

export default withScriptjs(withGoogleMap(PiqueroqueMap));
