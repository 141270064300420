/**
 * index.js
 *
 * Entry file for our application.
 */

import createHistory from "history/createBrowserHistory";
import { ConnectedRouter } from "react-router-redux";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import FontFaceObserver from "fontfaceobserver";
import { addLocaleData } from "react-intl";
import enLocaleData from "react-intl/locale-data/en";
import frLocaleData from "react-intl/locale-data/fr";

// Import reset CSS & global styles
import "sanitize.css/sanitize.css";
import "./global-styles";

import configureStore from "./configureStore";

// Import all i18n dependencies
import messages from "./messages";
import LanguageProvider from "./containers/LanguageProvider";

// import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";

import App from "./containers/App";

// eslint-disable-next-line no-console
console.info(`Running for ${process.env.REACT_APP_ENV || "local"} env`);

// Observe loading of Raleway
const openSansObserver = new FontFaceObserver("Raleway", {});

// When Raleway is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(
  () => {
    document.body.classList.add("fontLoaded");
  },
  () => {
    document.body.classList.remove("fontLoaded");
  }
);

const initialState = {};
const history = createHistory();
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("root");

addLocaleData(enLocaleData);
addLocaleData(frLocaleData);

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <LanguageProvider messages={messages}>
          <App />
        </LanguageProvider>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  );
};

// If browser doesn't support Intl (i.e. Safari), then we manually import
// the intl polyfill and locale data.
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import("intl"));
  })
    .then(() =>
      Promise.all([
        import("intl/locale-data/jsonp/en.js"),
        import("intl/locale-data/jsonp/fr.js"),
      ])
    )
    .then(() => render(messages))
    .catch((err) => {
      throw err;
    });
} else {
  render(messages);
}

// registerServiceWorker();
unregister();
