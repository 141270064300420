import React from "react";
import withRouter from "react-router-dom/withRouter";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";
import { Box, Flex } from "grid-styled";
import { FormattedMessage, injectIntl } from "react-intl";

import Title, { TitleSeo } from "../../components/Title";
import Button from "../../components/Button";
import Photos from "./Photos";
import PriceTab from "./PriceTab";

class Place extends React.Component {
  state = { render: true };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.setState({ render: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ render: true });
    }
  }

  render() {
    const { place, type, intl } = this.props;

    if (!place || !this.state.render)
      return <div style={{ minHeight: "100vh" }} />;

    return (
      <div>
        <Helmet>
          <title>{place.title}</title>
          <link
            rel="alternate"
            href={
              type === "cottage"
                ? `https://piqueroque.fr/gite`
                : `https://piqueroque.fr/chambre-hotes`
            }
            hreflang="x-default"
          />
          <link
            rel="alternate"
            href={
              type === "cottage"
                ? `https://piqueroque.fr/fr/gite`
                : `https://piqueroque.fr/fr/chambre-hotes`
            }
            hreflang="fr"
          />
          <link
            rel="alternate"
            href={
              type === "cottage"
                ? `https://piqueroque.fr/en/cottage`
                : `https://piqueroque.fr/en/guestroom`
            }
            hreflang="en"
          />
        </Helmet>
        <Flex
          width={[1, 1, 900]}
          px={2}
          mx="auto"
          my={3}
          column
          align="center"
          is="section"
          style={{ textAlign: "center" }}
        >
          <TitleSeo>{place.title}</TitleSeo>
          <ReactMarkdown source={place.description} />
        </Flex>
        <Photos photos={place.photos} />
        <Flex
          width={[1, 1, 900]}
          px={2}
          mx="auto"
          my={3}
          column
          align="center"
          is="section"
        >
          <Title>
            <FormattedMessage id="place.prices.title" />
          </Title>
          <Flex
            width={1}
            align="center"
            direction={["column", "column", "row"]}
          >
            <Box width={[1, 1, 1 / 2]} mr={[0, 0, 1]} my={2}>
              <PriceTab {...place.lowSeason} />
            </Box>
            <Box width={[1, 1, 1 / 2]} ml={[0, 0, 1]} my={2}>
              <PriceTab {...place.highSeason} />
            </Box>
          </Flex>
          {place.taxes && (
            <Box
              width={1}
              mx={[0, 0, 1]}
              px={2}
              py={1}
              style={{ border: "1px solid #792037", textAlign: "center" }}
            >
              {place.taxes}
            </Box>
          )}
          <Box
            width={1}
            mx={[0, 0, 1]}
            my={2}
            style={{ alignSelf: "flex-start", textAlign: "left" }}
          >
            <ReactMarkdown source={place.information} />
          </Box>
          <Button
            variant="primary"
            to={intl.formatMessage({ id: "route.contact" })}
          >
            {intl.formatMessage({ id: "place.button.contact" })}
          </Button>
        </Flex>
      </div>
    );
  }
}

export default withRouter(injectIntl(Place));
