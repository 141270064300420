import React, { Component } from "react";
import { Flex, Box } from "grid-styled";
import { responsiveStyle } from "styled-system";
import { FormattedMessage, injectIntl } from "react-intl";

import NavLink from "./NavLink";
import SocialBar from "./SocialBar";
import { FacebookIcon, InstagramIcon, TripAdvisorIcon } from "./SocialIcons";

const display = responsiveStyle({
  prop: "display",
  cssProperty: "display",
});

const NavWrapper = Flex.extend`
  height: 100%;
  ${display};
`;

class NormalNav extends Component {
  render() {
    const { intl } = this.props;

    return (
      <NavWrapper display={["none", "none", "flex"]} width={1}>
        <Flex
          width={1}
          align="center"
          justify={["space-evenly", "space-evenly", "flex-start"]}
          ml={[0, 0, 50]}
        >
          <Box px={[0, 0, 15]}>
            <NavLink to={`/${intl.locale}`} exact>
              <FormattedMessage id="header.home" />
            </NavLink>
          </Box>
          <Box px={[0, 0, 15]}>
            <NavLink to={intl.formatMessage({ id: "route.cottage" })}>
              <FormattedMessage id="header.cottage" />
            </NavLink>
          </Box>
          <Box px={[0, 0, 15]}>
            <NavLink to={intl.formatMessage({ id: "route.guestroom" })}>
              <FormattedMessage id="header.guestroom" />
            </NavLink>
          </Box>
          <Box px={[0, 0, 15]}>
            <NavLink to={intl.formatMessage({ id: "route.contact" })}>
              <FormattedMessage id="header.contact" />
            </NavLink>
          </Box>
        </Flex>
        <SocialBar mr={[0, 0, 100]} align="center">
          <Box px={[0, 0, 25]}>
            <a
              href="https://www.tripadvisor.fr/VacationRentalReview-g1934712-d6653677-PIQUEROQUE_Guest_room-Gironde_Nouvelle_Aquitaine.html"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <TripAdvisorIcon />
            </a>
          </Box>
          <Box px={[0, 0, 25]}>
            <a
              href="https://www.facebook.com/piqueroque.chambre.hotes.gite/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <FacebookIcon />
            </a>
          </Box>
          <Box px={[0, 0, 25]}>
            <a
              href="https://www.instagram.com/piqueroque/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <InstagramIcon />
            </a>
          </Box>
        </SocialBar>
      </NavWrapper>
    );
  }
}

export default injectIntl(NormalNav);
