import styled from "styled-components";
import { responsiveStyle } from "styled-system";

const backgroundImage = responsiveStyle({
  prop: "picture",
  cssProperty: "background-image"
});

const ResponsiveCover = styled.div`
  ${backgroundImage};
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
`;

export default ResponsiveCover;
