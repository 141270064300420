import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { injectIntl } from "react-intl";

import CoverSection from "./CoverSection";
import LinksSection from "./LinksSection";
import MapSection from "./MapSection";
import PhotosSection from "./PhotosSection";
import ServicesSection from "./ServicesSection";
// import VideoSection from "./VideoSection";
import WelcomeSection from "./WelcomeSection";
import YoutubeVideoSection from "./YoutubeVideoSection";

import messages from "./messages";
import picture1 from "./assets/picture1.jpg";
import picture2 from "./assets/picture2.jpg";
import picture3 from "./assets/picture3.jpg";
import lunch from "./assets/lunch-icon.svg";
import swim from "./assets/swim-icon.svg";
import wifi from "./assets/wifi-icon.svg";
import airbnb from "./assets/airbnb.png";
import booking from "./assets/booking.png";
import tripadvisor from "./assets/tripadvisor.png";
import chambreshotes from "./assets/chambreshotes.png";

class Home extends React.Component {
  render() {
    const { intl } = this.props;
    const services = [
      {
        icon: lunch,
        title: intl.formatMessage(messages.dinerTitle),
        description: intl.formatMessage(messages.dinerDescription),
      },
      {
        icon: swim,
        title: intl.formatMessage(messages.swimTitle),
        description: intl.formatMessage(messages.swimDescription),
      },
      {
        icon: wifi,
        title: intl.formatMessage(messages.wifiTitle),
        description: intl.formatMessage(messages.wifiDescription),
      },
    ];

    const photos = [
      {
        photo: picture2,
        description: "2",
      },
      {
        photo: picture1,
        description: "1",
      },
      {
        photo: picture3,
        description: "3",
      },
    ];

    const links = [
      {
        title: "TripAdvisor",
        logo: tripadvisor,
        href:
          "https://www.tripadvisor.fr/VacationRentalReview-g1934712-d6653677-PIQUEROQUE_Guest_room-Gironde_Nouvelle_Aquitaine.html",
      },
      {
        title: "Airbnb",
        logo: airbnb,
        href: "https://www.airbnb.fr/rooms/2622621?s=2FE9",
      },
      {
        title: "Booking.com",
        logo: booking,
        href: "https://www.booking.com/hotel/fr/piqueroque.fr.html",
      },
      {
        title: "Chambres-Hotes.fr",
        logo: chambreshotes,
        href:
          "http://www.chambres-hotes.fr/chambres-hotes_saint-philippe-d-aiguille_13932.html",
      },
    ];

    return (
      <div>
        <Helmet>
          <title>{intl.formatMessage(messages.titlePage)}</title>
          <link rel="alternate" href="https://piqueroque.fr/fr" hreflang="fr" />
          <link rel="alternate" href="https://piqueroque.fr/en" hreflang="en" />
          <link
            rel="alternate"
            href="https://piqueroque.fr/"
            hreflang="x-default"
          />
        </Helmet>
        <CoverSection />
        <WelcomeSection
          title={intl.formatMessage(messages.welcomeTitle)}
          text={intl.formatMessage(messages.welcomeMessage)}
          cottageButton={intl.formatMessage(messages.cottageButton)}
          guestRoomButton={intl.formatMessage(messages.guestRoomButton)}
        />
        <ServicesSection
          title={intl.formatMessage(messages.servicesTitle)}
          services={services}
        />
        <PhotosSection
          title={intl.formatMessage(messages.galleryTitle)}
          photos={photos}
        />
        <LinksSection links={links} />
        <YoutubeVideoSection />
        <MapSection />
      </div>
    );
  }
}

function mapStateToProps({ language }) {
  return {
    locale: language.locale,
  };
}

export default connect(mapStateToProps)(injectIntl(Home));
