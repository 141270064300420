import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import NavLink from "react-router-dom/NavLink";
import withRouter from "react-router-dom/withRouter";

import { changeLocale } from "./../../containers/LanguageProvider/actions";

const LanguageWrapper = styled.div`
  background-color: #404040;
  display: flex;
  align-items: center;
  width: 100px;
  height: 100%;
  padding: 0 25px;
  color: rgba(255, 255, 255, 0.6);
`;

const Lang = styled(NavLink)`
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

const ActiveLang = styled.span`
  text-transform: uppercase;
  color: rgba(255, 255, 255, 1);
`;

const LANGS = ["fr", "en"];

const ROUTES = {
  "/": "/en",
  "/gite": "/en/cottage",
  "/chambre-hotes": "/en/guestroom",
  "/contact": "/en/contact",
  "/fr": "/en",
  "/fr/gite": "/en/cottage",
  "/fr/chambre-hotes": "/en/guestroom",
  "/fr/contact": "/en/contact",
  "/en": "/fr",
  "/en/cottage": "/fr/gite",
  "/en/guestroom": "/fr/chambre-hotes",
  "/en/contact": "/fr/contact",
};

class LanguageBar extends Component {
  handleClick = (lang) => {
    if (this.props.locale !== lang) {
      this.props.changeLocale(lang);
    }
  };

  render() {
    const { location, locale } = this.props;

    return (
      <LanguageWrapper>
        {LANGS.map((lang) =>
          locale !== lang ? (
            <Lang
              onClick={() => this.handleClick(lang)}
              to={ROUTES[location.pathname]}
              key={lang}
            >
              {lang}
            </Lang>
          ) : (
            <ActiveLang key={lang}>{lang}</ActiveLang>
          )
        ).reduce((prev, curr) => [prev, "/", curr])}
      </LanguageWrapper>
    );
  }
}

function mapStateToProps({ language }) {
  return {
    locale: language.locale,
  };
}

export default withRouter(
  connect(mapStateToProps, { changeLocale })(LanguageBar)
);
