import React from "react";
import { Flex, Box } from "grid-styled";

const Link = ({ title, logo, href }) => (
  <Box width={[1 / 2, 1 / 3]} px={1} py={1}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img
        src={logo}
        alt={title}
        style={{ maxWidth: 200, width: "100%" }}
        loading="lazy"
      />
    </a>
  </Box>
);

class LinksSection extends React.PureComponent {
  render() {
    return (
      <Flex
        width={[1, 1, 900]}
        px={[1, 1, 0]}
        mx="auto"
        my={3}
        align="center"
        justify="center"
        wrap
        is="section"
        style={{ textAlign: "center" }}
      >
        {this.props.links.length &&
          this.props.links.map((link, index) => <Link {...link} key={index} />)}
      </Flex>
    );
  }
}

export default LinksSection;
