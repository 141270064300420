import React from "react";
import { Flex, Box } from "grid-styled";

import Card from "../../components/Card";
import Title from "../../components/Title";

const Wrapper = Flex.extend`background: #792037;`;

const CardService = ({ title, icon, description }) => (
  <Box width={290} px={2} my={2}>
    <Card icon={icon} title={title} description={description} />
  </Box>
);

class ServicesSection extends React.PureComponent {
  render() {
    return (
      <Wrapper width={1} my={3} column align="center" is="section">
        <Flex width={1} pt={50} pb={75} align="center" column>
          <Title light>{this.props.title}</Title>
          <Flex
            width={[1, 1, 900]}
            px={[1, 1, 0]}
            mx="auto"
            direction={["column", "row", "row"]}
            align="center"
            justify="center"
          >
            {this.props.services.length &&
              this.props.services.map((service, index) => (
                <CardService {...service} key={index} />
              ))}
          </Flex>
        </Flex>
      </Wrapper>
    );
  }
}

export default ServicesSection;
