import { Flex } from 'grid-styled';

const SocialBar = Flex.extend`
  svg {
    fill: rgba(255, 255, 255, 0.6);

    &:hover {
      fill: rgba(255, 255, 255, 1);
      transition: fill 200ms ease-in-out;
    }
  }
`;

export default SocialBar;
