export default {
  fr: {
    "route.home": "/fr",
    "route.cottage": "/fr/gite",
    "route.guestroom": "/fr/chambre-hotes",
    "route.contact": "/fr/contact",
    "header.home": "Accueil",
    "header.cottage": "Le gîte",
    "header.guestroom": "La chambre d'hôtes",
    "header.contact": "Contact",
    "footer.terms": "Conditions générales de ventes",
    "footer.legals": "Mentions légales",
    "footer.seo": "Gîte et chambres d'hôtes à St-Emilion",
    "home.seo": "Gîte et chambres d'hôtes à St-Emilion",
    "home.banner.title": "Infos COVID-19",
    "home.banner.message":
      "Pour la sécurité de tous, nous mettons en place des **mesures sanitaires renforcées** (entrée indépendante, distanciation sociale, mise à disposition de masques et gel hydroalcoolique et désinfection après chaque séjour).",
    "home.titlePage":
      "Piqueroque - gîte et chambres d'hôtes près de St-Emilion",
    "home.welcomeTitle": "Bienvenue",
    "home.welcomeMessage":
      'A dix minutes de Saint-Emilion, à mi-chemin entre Bordeaux et Bergerac et à deux pas du nouveau "Grand Saint Emilionnais Golf Club", Agnès et Didier Cerbelle vous accueillent au coeur des vignobles des Côtes de Castillon.<br><br>Au milieu des bois et des vignes, sur une propriété de cinq hectares, nous vous proposons une chambre d\'hôtes dans la maison principale et un gîte indépendant niché sur les hauteurs.',
    "home.cottageButton": "Voir le gîte",
    "home.guestRoomButton": "Voir la chambre d'hôtes",
    "home.servicesTitle": "Nos prestations",
    "home.services.diner.title": "Panier gourmand",
    "home.services.diner.description":
      "Assiettes de produits locaux sur demande livrées à domicile.",
    "home.services.swim.title": "Piscine chauffée",
    "home.services.swim.description":
      "La grande piscine chauffée (12x5m) vous permettra de vous détendre et de vous rafraîchir.",
    "home.services.wifi.title": "Wifi gratuit",
    "home.services.wifi.description":
      "Le wifi est disponible gratuitement dans la chambre et dans le gîte.",
    "home.galleryTitle": "Photos",
    "place.prices.title": "Tarifs",
    "place.prices.lowSeason.title": "Basse saison",
    "place.prices.lowSeason.date": "Du 1/10 au 31/04",
    "place.prices.highSeason.title": "Haute saison",
    "place.prices.highSeason.date": "Du 1/05 au 31/09",
    "place.cottage": "Le gîte",
    "place.cottage.title": "Le gîte",
    "place.cottage.description":
      'Cette ancienne maison de bordier de 80 m2, entièrement rénovée, peut accueillir 4 personnes.<br><br>Indépendant, avec une belle vue plongeante sur les coteaux de vignes et les bois, ce gîte de caractère vous propose :<br><br>Au rez-de-chaussée :<br>Une grande pièce "salon-salle à manger" avec un canapé, une cuisine "américaine" et une salle d\'eau avec WC, lave-mains et douche.<br><br>A l’étage :<br>Un "palier-bibliothèque" dessert deux chambres et une salle d\'eau.<br>Les deux chambres (18 et 12 m2) profitent de la vue sur le vallon. La salle d’eau est équipée d’une douche et de WC.<br><br>La semaine s\'effectue du samedi 17h au samedi 11h.<br>Les draps de bain et de piscine sont fournis.',
    "place.cottage.prices.firstColumn.label": "Une semaine*",
    "place.cottage.prices.secondColumn.label": "Deux semaines",
    "place.cottage.prices.taxes": "Taxe de séjour et ménage (80€) en sus",
    "place.cottage.prices.information":
      "Les tarifs comprennent :<br><br>• la fourniture des draps, linge de toilette, draps de bain. Chauffage non inclus.<br><br>* Pour les séjours de 3-4 jours, nous consulter.",
    "place.guestroom": "La chambre d'hôtes",
    "place.guestroom.description":
      "Au rez-de-chaussée de la maison principale, une grande chambre (21 m2) avec son entrée indépendante par une lumineuse baie vitrée donnant sur une terrasse bois privative.<br><br>La salle d’eau attenante (8 m2) est équipée d'une large douche italienne, d'une grande vasque en marbre noir et de WC.<br><br>Les arrivées sont prévues entre 17h00 et 20h00 et les départs au plus tard à 11h00.<br>Les draps de bain et de piscine sont fournis.",
    "place.guestroom.prices.firstColumn.label": "Une seule nuit",
    "place.guestroom.prices.secondColumn.label": "Deux nuits et plus*",
    "place.guestroom.prices.information":
      "Les tarifs comprennent :<br><br>• la nuitée pour 2 personnes<br>• le petit-déjeuner<br>• la fourniture des draps, linge de toilette, draps de bain, chauffage<br><br>* Pour les séjours de longue durée, nous consulter.",
    "place.button.contact": "Contactez-nous pour plus d'informations",
    "contact.title": "Contactez-nous",
    "contact.message":
      "Pour réserver un séjour, merci de nous contacter par l'un des moyens suivant :",
    "contact.or": "ou",
    "contact.mail": "a.cerbelle@gmail.com",
    "contact.subject": "Demande de réservation - Piqueroque",
    "contact.phone": "06 28 20 46 54",
    "contact.form.disabled":
      "Le formulaire est indisponible pour le moment. Merci de prendre contact avec nous par email à a.cerbelle@gmail.com ou par téléphone au 06 28 20 46 54",
    "contact.form.name": "Nom",
    "contact.form.email": "Email",
    "contact.form.object": "Objet",
    "contact.form.message": "Message",
    "contact.form.send": "Envoyer",
    "contact.form.sending":
      "Envoi en cours ... Merci de ne pas quittez la page.",
    "contact.form.success": "Message envoyé ! Nous vous répondrons sous peu.",
    "contact.form.error":
      "Désolé, votre message n'a pas pu être envoyé. Merci de rafraichîr la page et de réesayer ou bien d'utiliser notre email de contact ci-dessus.",
    "notfound.title": "Page introuvable",
    "notfound.message":
      "La page à laquelle vous tentez d'accéder n'existe pas ou n'existe plus.",
  },
  en: {
    "route.home": "/en",
    "route.cottage": "/en/cottage",
    "route.guestroom": "/en/guestroom",
    "route.contact": "/en/contact",
    "header.home": "Home",
    "header.cottage": "The cottage",
    "header.guestroom": "The guest room",
    "header.contact": "Contact",
    "footer.terms": "Terms and conditions of sales",
    "footer.legals": "Legal notice",
    "footer.seo": "Cottage & guesthouse at St-Emilion",
    "home.seo": "Cottage & guesthouse at St-Emilion",
    "home.banner.title": "COVID-19 Information",
    "home.banner.message":
      "For the security of everyone we are implementing **strengthened health measures** (independent entrance, social distancing, provision of face mask and hand sanitiser gel and disinfection after each stay)",
    "home.titlePage": "Piqueroque - cottage & guesthouse at St-Emilion",
    "home.welcomeTitle": "Welcome",
    "home.welcomeMessage":
      'Ten minutes drive away from St Emilion, half distance between Bordeaux and Bergerac and just a step from the new "Grand Saint Emilionnais Golf Club", Agnès and Didier Cerbelle are welcoming you in the heart of the Côtes de Castillon vineyards.<br><br>Among woods and vines, on a large property, we offer you a guest–room in the main house and an independant cottage, hidden in the hills. You will relax and refresh in the large heated swimming–pool, shared with the owners.',
    "home.cottageButton": "See the cottage",
    "home.guestRoomButton": "See the guest room",
    "home.servicesTitle": "Our services",
    "home.services.diner.title": "Gourmet basket",
    "home.services.diner.description":
      "Delivered plates of local products on request.",
    "home.services.swim.title": "Heated swimming–pool",
    "home.services.swim.description":
      "You will relax and refresh in the large heated swimming-pool.",
    "home.services.wifi.title": "Free wifi",
    "home.services.wifi.description":
      "Wifi is available free of charge in the guest room and in the cottage.",
    "home.galleryTitle": "Gallery",
    "place.prices.title": "Rates",
    "place.prices.lowSeason.title": "Low season",
    "place.prices.lowSeason.date": "From 1/10 to 31/04",
    "place.prices.highSeason.title": "High season",
    "place.prices.highSeason.date": "From 1/05 to 31/09",
    "place.cottage": "The cottage",
    "place.cottage.description":
      'This former rural house, entirely restored, can welcome 4 persons.<br><br>Independant, with a nice view overhanging vines and woods hills, this cottage offers you :<br><br>On the ground floor :<br>a large "living-dining" room with a sofa, an "american" kitchen and a shower room with WC and wash basin.<br>On the first floor :<br>a "library-landing" gives access to a shower room with WC and two bedrooms (18 and 12 m2).<br><br>The week is from saturday 5:00 to saturday 11:00 am.<br>Towels and hair-drier provided.',
    "place.cottage.prices.firstColumn.label": "One week*",
    "place.cottage.prices.secondColumn.label": "Two week",
    "place.cottage.prices.taxes": "Taxes and cleaning fee (80€) extra",
    "place.cottage.prices.information":
      "Rates include :<br><br>• the supply of sheets, bathroom linen, bath towels. Heating not included.<br><br>* For 3-4 days, please contact us.",
    "place.guestroom": "The guest room",
    "place.guestroom.description":
      "In the main house ground floor, a large bedroom (21 m2) with its direct access by a bright bay-window opening onto its private wooden terrace.<br><br>The contiguous bathroom (8 m2), is equipped with a wide italian shower, a big black stone basin and toilet. Towels and hair-drier provided.<br><br>Check-in between 5:00 to 8:00 pm and check-out before 11:00 am.<br>Towels and hair-drier provided.",
    "place.guestroom.prices.firstColumn.label": "One night",
    "place.guestroom.prices.secondColumn.label": "Two night and more*",
    "place.guestroom.prices.information":
      "Rates include :<br><br>• the night for two<br>• breakfast<br>• the supply of sheets, bathroom linen, bath towels, heating.<br><br>* For longer stays, please contact us.",
    "place.button.contact": "Contact us to have more information",
    "contact.title": "Contact us",
    "contact.message": "Please contact us to book a travel :",
    "contact.or": "or",
    "contact.mail": "a.cerbelle@gmail.com",
    "contact.subject": "Booking - Piqueroque",
    "contact.phone": "+33628204654",
    "contact.form.disabled":
      "Contact form is unavailable now. Contact us by mail at a.cerbelle@gmail.com",
    "contact.form.name": "Name",
    "contact.form.email": "Email",
    "contact.form.object": "Object",
    "contact.form.message": "Message",
    "contact.form.send": "Send",
    "contact.form.sending": "Sending ... Please, do not quit the page.",
    "contact.form.success": "Message sent ! We will adress a response shortly.",
    "contact.form.error":
      "Sorry, your message has not been sent. Please refresh the page and retry or use our contact email above.",
    "notfound.title": "Not found",
    "notfound.message": "The page you want to access doesn't exist anymore.",
  },
};
