import styled from "styled-components";

const FooterWrapper = styled.footer`
  background-color: #343434;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;

  > * {
    margin: 0 10px;
  }

  h2 {
    font-size: 12px;
    font-weight: normal;
  }
`;

export default FooterWrapper;
