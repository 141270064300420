import { defineMessages } from "react-intl";

export default defineMessages({
  bannerTitle: {
    id: "home.banner.title",
  },
  bannerMessage: {
    id: "home.banner.message",
  },
  titlePage: {
    id: "home.titlePage",
  },
  welcomeTitle: {
    id: "home.welcomeTitle",
  },
  welcomeMessage: {
    id: "home.welcomeMessage",
  },
  cottageButton: {
    id: "home.cottageButton",
  },
  guestRoomButton: {
    id: "home.guestRoomButton",
  },
  servicesTitle: {
    id: "home.servicesTitle",
  },
  dinerTitle: {
    id: "home.services.diner.title",
  },
  dinerDescription: {
    id: "home.services.diner.description",
  },
  swimTitle: {
    id: "home.services.swim.title",
  },
  swimDescription: {
    id: "home.services.swim.description",
  },
  wifiTitle: {
    id: "home.services.wifi.title",
  },
  wifiDescription: {
    id: "home.services.wifi.description",
  },
  galleryTitle: {
    id: "home.galleryTitle",
  },
});
