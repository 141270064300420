import React from "react";
import styled from "styled-components";

import ResponsiveCover from "../../components/ResponsiveCover";
import Banner from "./Banner";

import logo from "./assets/logo.svg";
import mobileCover from "./assets/cover_mobile.jpg";
import desktopCover from "./assets/cover_desktop.jpg";

const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65vw;

  @media screen and (min-width: 40em) {
    width: 80vw;
    max-width: 400px;
  }
`;

const ShadowContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
`;

const Container = styled.div`
  width: 100%;
  height: 82vh;
  position: relative;
`;

class CoverSection extends React.PureComponent {
  render() {
    return (
      <Container>
        <ResponsiveCover
          picture={[
            `url(${mobileCover})`,
            `url(${desktopCover})`,
            `url(${desktopCover})`,
          ]}
        >
          <ShadowContainer>
            <Logo src={logo} alt="" loading="lazy" />
            <Banner />
          </ShadowContainer>
        </ResponsiveCover>
      </Container>
    );
  }
}

export default CoverSection;
