import React, { Component } from "react";
import { Flex, Box } from "grid-styled";
import { FormattedMessage, injectIntl } from "react-intl";
import Transition from "react-transition-group/Transition";
import onClickOutside from "react-onclickoutside";

import NavLink from "./NavLink";
import NavBar from "./NavBar";
import SocialBar from "./SocialBar";
import { FacebookIcon, InstagramIcon, TripAdvisorIcon } from "./SocialIcons";

const MobileNavWrapper = Flex.extend`
  background-color: #792037;
  position: fixed;
  top: 50px;
  left: 0;
  height: calc(100vh - 50px);
  opacity: ${(props) => (["entered"].includes(props.state) ? 1 : 0)};
  transform: translate3d(
    ${(props) => (["entered"].includes(props.state) ? 0 : "-245px")},
    0,
    0
  );
  transition: all ${(props) => props.duration}ms ease-in-out;
`;

const clickOutsideConfig = {
  handleClickOutside(instance) {
    return instance.props.handleClickOutside;
  },
};

const EnhancedNavWrapper = onClickOutside(MobileNavWrapper, clickOutsideConfig);

const DURATION = 150;

class MobileNav extends Component {
  constructor(props) {
    super(props);

    // Bind functions
    this.onClickOutside = this.onClickOutside.bind(this);
    this.onExited = this.onExited.bind(this);

    // Initialize state
    this.state = { show: false };
  }

  static lockBodyScroll(locked) {
    document.querySelector("body").style.overflow = locked ? "hidden" : "";
  }

  componentDidMount() {
    MobileNav.lockBodyScroll(true);

    setTimeout(() => {
      this.setState({ show: true });
    }, 0);
  }

  componentWillUnmount() {
    MobileNav.lockBodyScroll(false);
  }

  onClickOutside(event) {
    this.setState({ show: false });
    event.stopPropagation();
  }

  onExited() {
    this.props.toggleMobileNav();
  }

  render() {
    const { intl } = this.props;

    return (
      <Transition
        timeout={DURATION}
        in={this.state.show}
        onExited={this.onExited}
        appear
      >
        {(state) => (
          <EnhancedNavWrapper
            width={245}
            direction="column"
            justify="flex-between"
            pt={15}
            pb={30}
            handleClickOutside={this.onClickOutside}
            state={state}
            duration={DURATION}
          >
            <NavBar
              width={1}
              direction="column"
              align="flex-start"
              justify="flex-start"
              pl={20}
            >
              <Box py={15} onClick={this.props.toggleMobileNav}>
                <NavLink to={`/${intl.locale}`} exact>
                  <FormattedMessage id="header.home" />
                </NavLink>
              </Box>
              <Box py={15} onClick={this.props.toggleMobileNav}>
                <NavLink to={intl.formatMessage({ id: "route.cottage" })}>
                  <FormattedMessage id="header.cottage" />
                </NavLink>
              </Box>
              <Box py={15} onClick={this.props.toggleMobileNav}>
                <NavLink to={intl.formatMessage({ id: "route.guestroom" })}>
                  <FormattedMessage id="header.guestroom" />
                </NavLink>
              </Box>
              <Box py={15} onClick={this.props.toggleMobileNav}>
                <NavLink to={intl.formatMessage({ id: "route.contact" })}>
                  <FormattedMessage id="header.contact" />
                </NavLink>
              </Box>
            </NavBar>
            <SocialBar width={1} align="center" justify="center">
              <Box px={25}>
                <a
                  href="https://www.tripadvisor.fr/VacationRentalReview-g1934712-d6653677-PIQUEROQUE_Guest_room-Gironde_Nouvelle_Aquitaine.html"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <TripAdvisorIcon />
                </a>
              </Box>
              <Box px={25}>
                <a
                  href="https://www.facebook.com/piqueroque.chambre.hotes.gite/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <FacebookIcon />
                </a>
              </Box>
              <Box px={25}>
                <a
                  href="https://www.instagram.com/piqueroque/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <InstagramIcon />
                </a>
              </Box>
            </SocialBar>
          </EnhancedNavWrapper>
        )}
      </Transition>
    );
  }
}

export default injectIntl(MobileNav);
