import styled, { css } from "styled-components";
import RouterLink from "react-router-dom/NavLink";
import { darken } from "polished";

const variantPrimary = css`
  background-color: #792037;
  border: 2px solid #792037;
  color: #ffffff;

  :hover {
    background-color: ${darken(0.08, "#792037")};
    border: 2px solid ${darken(0.08, "#792037")};
  }
`;

const Button = styled(RouterLink)`
  display: block;
  padding: 0 20px;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-radius: 3px;
  background-color: #ffffff;
  border: 2px solid #792037;
  color: #792037;
  text-decoration: none;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  transition: all 250ms ease-in-out;
  margin: 10px;

  :hover {
    background-color: #792037;
    color: #ffffff;
  }

  &[disabled] {
    background-color: #343434;
  }

  ${(props) => (props.variant === "primary" ? variantPrimary : null)}
`;

export default Button;
