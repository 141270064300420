import { defineMessages } from 'react-intl';

export default defineMessages({
    lowSeason: {
      id: 'place.prices.lowSeason.title',
    },
    lowSeasonDate: {
      id: 'place.prices.lowSeason.date',
    },
    highSeason: {
      id: 'place.prices.highSeason.title',
    },
    highSeasonDate: {
      id: 'place.prices.highSeason.date',
    },
    cottage: {
      id: 'place.cottage',
    },
    cottageDescription: {
      id: 'place.cottage.description',
    },
    cottageFirstColumnLabel: {
      id: 'place.cottage.prices.firstColumn.label',
    },
    cottageSecondColumnLabel: {
      id: 'place.cottage.prices.secondColumn.label',
    },
    cottageTaxes: {
      id: 'place.cottage.prices.taxes',
    },
    cottagePricesInformation: {
      id: 'place.cottage.prices.information',
    },
    guestroom: {
      id: 'place.guestroom',
    },
    guestroomDescription: {
      id: 'place.guestroom.description',
    },
    guestroomFirstColumnLabel: {
      id: 'place.guestroom.prices.firstColumn.label',
    },
    guestroomSecondColumnLabel: {
      id: 'place.guestroom.prices.secondColumn.label',
    },
    guestroomPricesInformation: {
      id: 'place.guestroom.prices.information',
    }
});
