import React from 'react';

import Svg from './../../Svg';

export const FacebookIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="13" height="24" viewBox="0 0 13 24">
    <path d="M12.031.004L9.033 0C5.664 0 3.487 2.233 3.487 5.691v2.624H.471A.47.47 0 0 0 0 8.786v3.802c0 .26.212.471.471.471h3.016v9.592c0 .261.211.472.471.472h3.934c.26 0 .471-.211.471-.472v-9.592h3.525a.471.471 0 0 0 .472-.471l.002-3.802a.472.472 0 0 0-.473-.471H8.363V6.09c0-1.069.255-1.611 1.647-1.611l2.021-.001c.26 0 .471-.211.471-.472V.476c0-.26-.21-.471-.471-.472" />
  </Svg>
);

export const GoogleIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="29" height="18" viewBox="0 0 29 18">
    <path d="M18 8H9.6v2.8h5.124a6 6 0 1 1-1.459-6.019l2.032-2.21A9 9 0 1 0 9 18c4.354.001 8.165-3.091 9-7.2V8zM29 8.8h-2.8V6H23.8v2.8H21v2.399h2.799V14H26.2v-2.801H29z" />
  </Svg>
);

export const InstagramIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 29.56 29.56">
  <path d="M14.77 7.18a7.59 7.59 0 1 0 7.59 7.59 7.59 7.59 0 0 0-7.59-7.59zm0 12.52a4.93 4.93 0 1 1 4.93-4.93 4.93 4.93 0 0 1-4.93 4.93z"/>
    <circle cx="22.66" cy="6.9" r="1.77"/>
    <path d="M28.78 5.1A7.56 7.56 0 0 0 24.46.78a10.85 10.85 0 0 0-3.59-.69C19.29.02 18.79 0 14.78 0s-4.52 0-6.09.09A10.85 10.85 0 0 0 5.1.78 7.56 7.56 0 0 0 .78 5.1a10.85 10.85 0 0 0-.69 3.59C.02 10.27 0 10.77 0 14.78s0 4.52.09 6.09a10.85 10.85 0 0 0 .69 3.59 7.56 7.56 0 0 0 4.32 4.32 10.85 10.85 0 0 0 3.59.74c1.58.07 2.08.09 6.09.09s4.52 0 6.09-.09a10.85 10.85 0 0 0 3.59-.69 7.56 7.56 0 0 0 4.32-4.32 10.85 10.85 0 0 0 .69-3.59c.07-1.58.09-2.08.09-6.09s0-4.52-.09-6.09a10.85 10.85 0 0 0-.69-3.64zm-2 15.65a8.18 8.18 0 0 1-.51 2.77 4.9 4.9 0 0 1-2.81 2.81 8.18 8.18 0 0 1-2.74.51c-1.56.07-2 .09-6 .09s-4.41 0-6-.09a8.18 8.18 0 0 1-2.74-.51 4.89 4.89 0 0 1-2.82-2.81 8.18 8.18 0 0 1-.51-2.74c-.07-1.56-.09-2-.09-6s0-4.41.09-6a8.18 8.18 0 0 1 .51-2.77A4.89 4.89 0 0 1 5.98 3.2a8.18 8.18 0 0 1 2.74-.51c1.56-.07 2-.09 6-.09s4.41 0 6 .09a8.18 8.18 0 0 1 2.74.51 4.9 4.9 0 0 1 2.81 2.81 8.18 8.18 0 0 1 .51 2.74c.07 1.56.09 2 .09 6s0 4.43-.07 6z" />
  </Svg>
);

export const TripAdvisorIcon = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="28" height="17">
    <path d="M7.576 2.573c1.624.099 3.051.665 4.257 1.762 1.203 1.094 1.908 2.459 2.164 4.064.264-1.586.95-2.938 2.131-4.022 1.182-1.084 2.591-1.654 4.188-1.774-1.896-.842-3.895-1.201-5.954-1.24-2.339-.046-4.613.298-6.786 1.21M7.31 3.968C4.434 3.943 1.928 6.343 1.977 9.4c.047 2.85 2.397 5.268 5.393 5.242 2.93-.025 5.284-2.417 5.29-5.314a5.33 5.33 0 0 0-5.35-5.36m8.017 5.342c0 2.957 2.4 5.372 5.345 5.36 2.957-.01 5.344-2.357 5.342-5.347 0-3.08-2.5-5.395-5.457-5.335-2.918.06-5.203 2.413-5.23 5.322M17.015.419a14.43 14.43 0 0 1 2.203.515c1.262.389 2.453.929 3.574 1.63a.545.545 0 0 0 .273.079c1.316.005 2.631.003 3.947.005.105 0 .21.01.316.017 0 .025.004.036 0 .042l-.186.282c-.446.685-.827 1.4-1.052 2.192-.025.085-.03.157.028.24 1.171 1.682 1.545 3.529 1.056 5.52-.43 1.746-1.43 3.107-2.952 4.07-1.086.69-2.288 1.018-3.57 1.025a6.208 6.208 0 0 1-1.6-.206 6.708 6.708 0 0 1-3.29-1.94c-.12-.128-.232-.26-.36-.404l-1.433 2.138-1.41-2.108c-.033.017-.042.019-.048.025a.19.19 0 0 0-.028.029c-1.096 1.295-2.475 2.088-4.155 2.354a6.498 6.498 0 0 1-2.768-.156 6.592 6.592 0 0 1-3.262-2.033 6.593 6.593 0 0 1-1.602-3.408c-.23-1.206.051-2.37.12-2.651A6.744 6.744 0 0 1 1.86 5.409a.262.262 0 0 0 .03-.194c-.198-.728-.54-1.39-.94-2.023-.102-.161-.224-.31-.336-.465v-.053c.023.003.045.006.067.006 1.346 0 2.693.002 4.04-.001a.338.338 0 0 0 .172-.056A14.51 14.51 0 0 1 7.906 1.16C8.67.89 9.45.68 10.246.527A18.774 18.774 0 0 1 12.57.22c1.907-.146 3.432.056 4.445.198" />
    <path d="M22.82 9.244a2.16 2.16 0 0 0-2.15-2.162 2.171 2.171 0 0 0-2.178 2.153 2.182 2.182 0 0 0 2.162 2.173 2.17 2.17 0 0 0 2.165-2.164m-2.158-3.307a3.298 3.298 0 0 1 3.311 3.3 3.314 3.314 0 1 1-6.63.007c.002-1.876 1.55-3.338 3.32-3.307m-11.266 3.3A2.161 2.161 0 0 0 7.21 7.083c-1.168.002-2.146.988-2.144 2.16a2.185 2.185 0 0 0 2.171 2.166c1.182.003 2.162-.983 2.159-2.172m-2.156-3.3a3.296 3.296 0 0 1 3.307 3.303 3.312 3.312 0 0 1-3.15 3.314 3.316 3.316 0 0 1-3.48-3.314C3.921 7.35 5.48 5.906 7.24 5.938" />
    <path d="M20.663 8.146c.606 0 1.104.497 1.098 1.1a1.1 1.1 0 0 1-2.198.008 1.09 1.09 0 0 1 1.1-1.107m-13.429-.01a1.107 1.107 0 1 1 .003 2.214 1.093 1.093 0 0 1-1.11-1.113c0-.62.482-1.101 1.107-1.101" />
  </Svg>
);
