import React from 'react';
import Helmet from 'react-helmet';
import { Flex } from 'grid-styled';
import { injectIntl, defineMessages } from 'react-intl';

import Text from '../../components/Text';
import Title from '../../components/Title';

const messages = defineMessages({
    title: {
      id: 'notfound.title',
    },
    message: {
      id: 'notfound.message',
    },
});

const NotFound = ({ intl }) =>
  <div>
    <Helmet>
      <title>{intl.formatMessage(messages.title)}</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Flex
      width={[1, 1, 900]}
      px={2}
      mx="auto"
      my={3}
      column
      align="center"
      is="section"
    >
      <Title>{intl.formatMessage(messages.title)}</Title>
      <Text align="center">{intl.formatMessage(messages.message)}</Text>
    </Flex>
  </div>;

export default injectIntl(NotFound);
