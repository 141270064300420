import RouterLink from 'react-router-dom/NavLink';
import styled from 'styled-components';

const NavLink = styled(RouterLink)`
  position: relative;
  display: inline-flex;
  text-decoration: none;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;

  &.active,
  &:hover {
    color: rgba(255, 255, 255, 1);
    transition: color 200ms ease-in-out;
  }
`;

export default NavLink;
