import React from "react";
import styled from "styled-components";
import { Box } from "grid-styled";
import { injectIntl } from "react-intl";
import ReactMarkdown from "react-markdown";

import clean from "./assets/clean-icon.svg";

const BannerContainer = Box.extend`
  position: absolute;
  left: 50%;
  bottom: 16px;
  padding: 16px 72px 16px 24px;
  width: 96vw;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.9) url(${clean}) no-repeat bottom 8px right
    16px;
  border-radius: 10px;
  font-family: Helvetica, Arial, sans-serif;

  @media screen and (min-width: 40em) {
    width: 80%;
    max-width: 800px;
  }
`;

const BannerTitle = styled.h6`
  color: #792037;
  font-size: 16px;
  padding: 0;
  margin: 0 0 8px;

  @media screen and (min-width: 40em) {
    font-size: 24px;
  }
`;

const BannerMessage = styled.div`
  font-size: 14px;

  p {
    margin: 0;
  }

  @media screen and (min-width: 40em) {
    font-size: 16px;
  }
`;

class Banner extends React.PureComponent {
  render() {
    const { intl } = this.props;

    return (
      <BannerContainer>
        <BannerTitle>
          {intl.formatMessage({ id: "home.banner.title" })}
        </BannerTitle>
        <BannerMessage>
          <ReactMarkdown
            source={intl.formatMessage({ id: "home.banner.message" })}
          />
        </BannerMessage>
      </BannerContainer>
    );
  }
}

export default injectIntl(Banner);
