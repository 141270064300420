import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import FooterLink from "./FooterLink";
import FooterWrapper from "./FooterWrapper";

class Footer extends Component {
  render() {
    return (
      <FooterWrapper>
        <h2>
          <FormattedMessage id="footer.seo" />
        </h2>
        <FooterLink to="#">
          <FormattedMessage id="footer.terms" />
        </FooterLink>
        <FooterLink to="#">
          <FormattedMessage id="footer.legals" />
        </FooterLink>
        <span>&copy;{new Date().getFullYear()} ccerbelle</span>
      </FooterWrapper>
    );
  }
}

export default Footer;
