import React from 'react';
import { responsiveStyle } from 'styled-system';
import styled from 'styled-components';
import { Box } from 'grid-styled';
import Transition from 'react-transition-group/Transition';

import { CloseIcon, HamburgerIcon } from './NavIcons';

const display = responsiveStyle({
  prop: 'display',
  cssProperty: 'display'
});

const NavTogglerWrapper = Box.extend`
  ${display}
`;

const ToggleIcon = styled.span`
  display: ${props => (['entered'].includes(props.state) ? 'inline' : 'none')};
  opacity: ${props => (['entered'].includes(props.state) ? 1 : 0)};
  transition: all ${props => props.duration}ms ease-in-out;
  cursor: pointer;
`;

const DURATION = 80;

const NavToggler = ({ isMobileNavOpened = false, onClick }) => (
  <NavTogglerWrapper onClick={onClick} display={['inline', 'inline', 'none']} is="span">
    <Transition
      timeout={DURATION}
      in={isMobileNavOpened}
      appear
    >
      {state => <ToggleIcon state={state} duration={DURATION}><CloseIcon /></ToggleIcon> }
    </Transition>
    <Transition
      timeout={DURATION}
      in={!isMobileNavOpened}
      appear
    >
      {state => <ToggleIcon state={state} duration={DURATION}><HamburgerIcon /></ToggleIcon> }
    </Transition>
  </NavTogglerWrapper>
);

export default NavToggler;
